import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";

import { IAuthResponse, IChildrenResponse } from "../model/auth.response.interface";
import { User } from "../model/user.model";
import { State } from "../model/state.model";

@Injectable()
export class ApiService {
    // private baseUrl: string = "http://localhost:23416";
    // private baseUrl: string = "http://localhost:8101";
    private baseUrl: string = "https://ycdapp.ycda.co.il";
    // private baseUrl: string = "https://ycdapp.win1.tempurl.info";

    constructor(
        private http: HttpClient,
    ) {
    }

    public authenticate(name: string, password: string): Observable<IAuthResponse> {
        return this.sendRequest(`/MyDjPlayer/Login?User=${name}&Password=${password}`);
    }

    public getChildUsers(name: string, password: string): Observable<IChildrenResponse[]> {
        return this.sendRequest(`/MyDjPlayer/GetChildUsers?User=${name}&Password=${password}`);
    }

    public sendCommand(user: User, command: string): Observable<State[]> {
        return this.sendRequest(`/MyDjPlayer/APIPhonePlayer?User=${user.name}&Password=${user.password}&Command=${command}`);
    }

    private sendRequest(url: string): Observable<any> {
        return this.http.jsonp(this.baseUrl + url, 'callback');
    }
}
